import * as THREE from 'three';
import React from 'react';

/* The first ~2 seconds of S1-Drill.data, in which our intrepid hero reaches toward a refridgerator door and starts opening and closing it.
I copied the data in as a string for this lab because I didn't know how to reference a data file in the developer build without first pushing the file 
to the remote repo. Please let me know if we've already figured out how to work around this.
*/
const inputData = 
`0 40 1031 49 -255 958 250 -232 969 44 42 965 322 321 998 30 108 931 -367 300 924 151 -186 915 77 -395 1018 136 -261 916 371 -86 969 244 NaN NaN NaN -950 -225 -33 -1340 -288 -144 587 -431 -258 450 -648 298 538 -985 38 180 -924 44 -40 352 -81 -706 79 -627 25 775 -905 350 302 -787 504 58 331 298 -700 304 598 112 -733 -976 95 25 -1099 -141 -104 441 -357 513 680 -210 669 215 -950 -302 208 -995 -452 -271 517 71 616 762 -163 624 -65 59 -30 5 -10 7 -17 -65 497 -885 -24 -35 -14 -35 24 -14 151 87 -22 17 -2 3 67 -273 358 -817 38 34 21 34 -38 21 168 183 -963 202 418 416 578 -816 -61 413 411 172 -961 245 421 428 -1 -108 -973 416 418 -895 146 447 417 410 -175 -118 -963 414 413 -38 -47 -1023 418 418 -641 -305 720 419 417 219 -851 673 425 412 -28 37 -1009 413 411 379 172 938 413 414 44 -993 235 418 414 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 29 -127 9 -980 -1007 15 -84 -1007 -109 -28 23 66 -1026 49 -86 -1000 50 39 -1060 -1022 38 -1020 5887 2505 1467 3276 1728 1530 6230 2829 917 5839 2764 1815 0 0 0 0 0 0 0
33 49 1043 42 -244 954 257 -242 979 31 62 964 282 287 1041 73 111 949 -373 290 905 163 -111 917 65 -375 1011 82 -273 918 356 -159 978 123 NaN NaN NaN -933 -260 -29 -1460 -311 -114 583 -415 -283 436 -657 281 547 -951 -5 163 -1190 -7 23 353 -59 -709 65 -629 10 775 -882 336 294 -1159 546 183 341 313 -683 319 599 126 -724 -966 28 -6 -1178 -185 -128 444 -375 498 678 -224 662 227 -923 -254 183 -1026 -512 -224 528 57 608 764 -177 617 -57 59 -30 5 13 -5 -25 -94 500 -890 22 -16 -26 -16 -22 -26 151 87 -22 17 1 1 63 -274 358 -821 69 80 12 80 -69 12 168 174 -958 200 417 416 561 -828 -83 413 413 119 -951 262 420 429 -8 -80 -991 415 418 -900 157 445 417 410 -181 -88 -963 414 413 -40 -45 -1003 415 417 -641 -295 720 419 418 206 -839 668 424 412 -28 47 -1000 416 412 373 163 921 412 413 14 -965 235 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 995 -85 19 -127 9 -975 -990 19 -90 -1009 -112 -25 9 66 -980 39 -87 -994 35 44 -1069 -1012 24 -1015 5892 2504 1465 3311 1739 1505 6230 2828 917 5840 2765 1815 0 0 0 0 0 0 0
67 17 1049 84 -243 958 278 -256 984 2 71 970 283 366 1099 -60 76 968 -350 262 919 188 -143 1004 3 -370 995 72 -254 900 286 -173 956 156 NaN NaN NaN -924 -279 -20 -1441 -356 -134 579 -397 -310 423 -665 263 557 -932 -39 146 -1321 -42 97 355 -30 -711 49 -630 -6 775 -883 304 297 -1383 482 297 356 331 -660 338 597 141 -714 -955 -13 -9 -1246 -195 -112 449 -392 478 675 -239 655 240 -901 -236 135 -1193 -546 -125 542 41 600 766 -193 611 -45 59 -30 5 19 1 -18 -100 489 -887 10 0 -5 0 -10 -5 151 87 -22 17 -2 -3 62 -271 364 -821 50 55 10 55 -50 10 168 193 -958 202 418 415 559 -819 -84 414 412 123 -957 262 420 431 7 -84 -1008 415 419 -896 162 439 416 410 -183 -101 -981 414 414 -59 -41 -1007 417 417 -632 -286 709 421 420 201 -855 674 424 414 -43 48 -998 414 412 372 157 914 413 414 39 -976 253 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 991 -85 18 -127 9 -980 -970 0 -50 -1008 -116 -19 9 48 -1004 48 -76 -1009 40 30 -1070 -1003 35 -1019 5901 2503 1463 3347 1751 1479 6232 2828 918 5842 2766 1816 0 0 0 0 0 0 0
100 23 1051 98 -234 969 295 -263 972 -10 60 973 291 255 1104 24 68 962 -380 266 929 160 -152 1088 22 -386 1004 87 -259 960 329 -99 974 262 NaN NaN NaN -932 -292 -20 -1381 -374 -91 575 -381 -336 410 -671 246 566 -940 -55 147 -1421 -81 231 351 -2 -713 30 -631 -22 775 -904 299 284 -1539 414 317 366 353 -635 357 593 158 -704 -970 -46 -5 -1282 -129 -53 452 -412 458 672 -255 648 253 -904 -271 135 -1320 -550 21 550 17 589 768 -208 605 -30 59 -30 5 11 -7 -33 -97 505 -896 17 67 0 67 -17 0 151 87 -22 17 -2 -1 72 -267 358 -814 27 18 9 18 -27 9 168 185 -958 199 417 414 559 -841 -91 414 412 146 -966 272 421 428 -7 -86 -978 414 419 -896 153 462 416 410 -192 -96 -984 414 414 -38 -61 -1015 416 415 -650 -294 725 419 418 211 -856 682 425 409 -52 45 -989 413 412 383 175 907 412 415 30 -969 242 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -85 19 -127 9 -980 -978 17 -76 -1000 -109 -22 17 66 -996 49 -76 -1000 30 39 -1060 -977 19 -1030 5910 2501 1461 3381 1761 1454 6232 2827 918 5843 2767 1816 0 0 0 0 0 0 0
133 -3 1045 102 -213 975 273 -255 1029 -44 71 980 287 290 1125 31 59 990 -367 260 918 190 -173 997 55 -376 1037 94 -236 1000 384 -47 1054 204 NaN NaN NaN -938 -299 -50 -1260 -372 -63 570 -364 -361 398 -677 231 574 -970 -112 163 -1460 -78 335 350 30 -714 11 -631 -38 774 -954 277 270 -1694 306 431 383 377 -604 379 587 175 -694 -974 -83 10 -1198 -112 6 453 -432 437 667 -269 641 266 -898 -284 136 -1199 -546 190 566 -10 578 769 -221 600 -14 59 -30 5 3 -2 -20 -86 499 -886 -13 73 28 73 13 28 151 87 -22 17 5 0 76 -270 354 -810 12 50 -15 50 -12 -15 168 169 -958 207 417 415 560 -816 -90 415 413 166 -975 265 421 427 -18 -95 -987 416 418 -889 153 464 417 412 -193 -95 -984 414 414 -31 -67 -1013 415 416 -639 -285 747 418 418 224 -846 676 425 411 -28 54 -988 413 410 375 167 919 414 415 31 -975 234 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 995 -85 14 -127 4 -975 -990 38 -50 -1004 -112 -15 5 80 -989 39 -86 -1000 40 49 -1060 -1000 33 -1015 5916 2498 1457 3408 1769 1431 6229 2825 918 5841 2766 1814 0 0 0 0 0 0 0
167 -9 1042 93 -196 974 256 -259 1074 -10 88 983 301 361 1145 19 42 996 -346 275 930 200 -208 1000 88 -358 1047 120 -251 1022 352 -84 1078 223 NaN NaN NaN -953 -322 -54 -1163 -329 -48 564 -348 -383 387 -682 216 582 -983 -148 184 -1449 -137 406 347 57 -715 -8 -630 -53 775 -984 211 257 -1599 149 531 393 394 -572 400 580 189 -684 -977 -65 29 -1164 -108 64 452 -449 417 663 -282 635 280 -893 -284 130 -1065 -477 321 578 -33 567 770 -231 595 2 59 -30 5 -3 6 -25 -75 498 -893 -13 78 64 78 13 64 151 87 -22 17 -4 1 67 -263 359 -821 -5 16 3 16 5 3 168 157 -958 212 416 415 558 -816 -82 415 411 136 -962 250 421 428 1 -95 -997 415 420 -918 158 468 415 412 -194 -84 -973 415 415 -36 -59 -999 415 417 -656 -287 728 420 418 224 -842 654 426 412 -38 56 -976 414 410 374 164 919 413 415 20 -963 221 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 17 -129 1 -980 -970 28 -70 -1001 -107 -18 37 73 -998 46 -94 -985 40 57 -1070 -996 30 -1030 5923 2495 1453 3432 1776 1411 6226 2824 918 5839 2765 1813 0 0 0 0 0 0 0
200 19 1034 111 -212 953 230 -197 1045 -77 98 996 320 423 1121 -84 38 968 -350 284 927 188 -195 1035 94 -354 1057 161 -206 1007 358 -14 1102 315 NaN NaN NaN -964 -355 -40 -993 -290 -16 560 -331 -399 377 -686 204 588 -996 -178 195 -1328 -133 417 347 82 -713 -26 -629 -66 774 -1011 206 240 -1310 29 674 400 402 -548 419 574 197 -675 -1009 -80 23 -1189 -73 150 452 -467 398 657 -294 628 294 -923 -284 135 -1157 -411 536 583 -63 561 769 -239 592 22 59 -29 4 25 -24 -77 -111 530 -931 117 146 72 146 -117 72 152 87 -22 17 0 2 69 -266 355 -818 -13 -16 -22 -16 13 -22 168 162 -959 211 416 416 567 -804 -87 415 412 155 -945 234 421 427 7 -104 -995 415 419 -905 162 459 418 411 -202 -88 -965 413 416 -28 -44 -1017 416 416 -635 -301 727 419 417 220 -853 667 424 411 -25 24 -964 414 410 365 165 938 412 416 26 -970 211 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -119 9 -980 -987 11 -75 -1009 -98 -10 21 66 -1000 49 -86 -1010 30 49 -1070 -1022 38 -1030 5927 2492 1451 3453 1781 1395 6225 2823 918 5839 2764 1812 0 0 0 0 0 0 0
233 5 1041 87 -223 980 219 -124 1025 -32 113 1003 348 258 937 115 77 939 -318 282 927 192 -234 1074 43 -313 1094 195 -189 959 433 -29 1016 243 NaN NaN NaN -962 -389 -49 -801 -310 -28 555 -320 -413 371 -689 193 593 -1008 -217 199 -1152 -118 490 346 104 -712 -41 -627 -76 774 -1041 202 224 -1048 -85 755 408 407 -524 436 569 201 -668 -1039 -116 19 -1302 -30 273 447 -484 378 650 -308 622 310 -941 -316 146 -1433 -345 699 589 -95 555 766 -250 590 46 59 -30 4 -2 -2 -15 -77 498 -880 -56 99 134 99 56 134 151 87 -22 17 -1 0 69 -265 357 -817 3 -3 -45 -3 -3 -45 168 165 -958 210 418 415 554 -830 -91 414 413 159 -948 257 421 428 -7 -96 -976 415 419 -898 146 457 418 411 -201 -93 -972 414 415 -51 -49 -1013 415 417 -617 -316 722 419 417 212 -838 669 425 412 -30 46 -967 414 411 365 175 930 414 415 29 -958 229 416 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -74 24 -127 9 -970 -970 28 -60 -1000 -102 -25 9 71 -1005 19 -86 -1010 40 39 -1080 -977 38 -1030 5929 2491 1449 3470 1786 1383 6225 2823 918 5839 2764 1812 0 0 0 0 0 0 0
267 71 1064 71 -210 985 198 -233 967 -11 141 1018 335 206 969 27 116 945 -315 307 932 159 -225 1055 -42 -321 1102 199 -252 901 393 -51 960 119 NaN NaN NaN -926 -399 -68 -474 -270 -7 551 -310 -423 367 -690 186 596 -974 -270 197 -848 -81 473 345 121 -708 -52 -626 -83 773 -1032 191 210 -656 -264 853 418 406 -506 450 565 199 -662 -999 -142 23 -1323 48 362 441 -507 355 641 -321 617 326 -902 -399 227 -1664 -340 803 591 -140 542 763 -264 586 73 60 -29 4 59 -103 -197 -179 628 -1009 317 316 -178 316 -317 -178 152 87 -22 17 5 4 73 -269 350 -815 -30 -28 -35 -28 30 -35 168 173 -959 219 418 415 556 -810 -81 414 412 141 -963 258 421 428 -5 -103 -1003 414 418 -889 149 449 416 410 -191 -103 -963 416 412 -57 -41 -1004 413 417 -646 -296 727 419 417 211 -836 679 426 411 -27 46 -1006 414 410 357 163 931 412 414 37 -966 236 415 416 0 0 0 0 0 0 0 0 0 0 0 0 0 998 -85 27 -127 9 -980 -980 9 -90 -1007 -109 -20 7 57 -1012 41 -96 -991 40 39 -1072 -981 21 -1022 5927 2490 1448 3486 1789 1374 6226 2823 919 5841 2763 1811 0 0 0 0 0 0 0
300 44 1056 12 -188 977 191 -253 956 4 181 984 331 234 959 10 153 925 -315 338 905 139 -182 993 -59 -292 1029 147 -234 869 334 -57 961 215 NaN NaN NaN -926 -400 -64 -352 -174 -66 547 -302 -432 365 -691 179 597 -962 -267 194 -454 -43 420 347 127 -706 -58 -626 -85 773 -1013 186 199 -231 -410 944 425 393 -495 462 562 190 -659 -980 -140 -10 -1526 171 343 433 -526 330 629 -337 611 344 -884 -477 268 -1759 -496 743 594 -184 525 760 -280 578 100 58 -28 4 -7 -307 -167 -226 782 -894 485 690 -708 690 -485 -708 151 87 -22 17 -9 7 71 -257 352 -820 -41 -39 -50 -39 41 -50 168 175 -958 222 420 415 536 -820 -70 414 412 122 -954 278 420 428 -26 -83 -999 415 418 -900 162 453 417 413 -197 -102 -979 413 413 -55 -41 -994 414 417 -637 -301 719 418 417 199 -868 675 424 409 -28 48 -995 414 411 404 152 925 413 414 22 -968 240 415 416 0 0 0 0 0 0 0 0 0 0 0 0 0 992 -66 17 -119 17 -980 -976 12 -92 -1009 -115 -20 29 66 -1017 29 -96 -989 40 49 -1060 -1000 29 -1010 5921 2490 1448 3500 1793 1366 6228 2824 919 5843 2764 1811 0 0 0 0 0 0 0
333 66 1057 15 -228 988 191 -242 943 23 177 934 305 230 953 27 173 937 -292 323 903 136 -136 931 -70 -292 1010 98 -258 861 350 -118 922 173 NaN NaN NaN -944 -370 -79 -98 -83 5 546 -300 -435 365 -692 177 597 -979 -225 181 -186 -72 361 345 129 -707 -61 -626 -84 773 -1010 217 189 95 -511 1030 432 378 -493 471 562 176 -657 -1015 -258 16 -1458 228 257 427 -547 305 617 -354 604 358 -896 -547 262 -1907 -548 738 598 -230 505 756 -299 568 128 55 -27 3 -203 -401 290 -94 715 -433 138 1898 -1767 1898 -138 -1767 148 87 -22 17 -8 5 64 -262 357 -825 -7 1 -24 1 7 -24 168 175 -968 212 418 416 563 -783 -80 415 412 169 -970 262 421 427 -5 -101 -986 415 419 -903 158 450 419 415 -183 -60 -982 414 414 -24 -62 -1009 415 416 -642 -300 713 419 419 217 -854 664 425 411 -33 51 -995 413 412 381 200 892 412 414 18 -968 238 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 995 -85 19 -122 9 -980 -990 9 -70 -1000 -112 -15 5 71 -1000 39 -105 -1010 50 49 -1080 -977 38 -1020 5912 2490 1449 3515 1796 1359 6231 2825 921 5847 2764 1811 0 0 0 0 0 0 0
367 25 1032 5 -251 969 194 -271 953 78 147 942 290 239 941 52 180 943 -297 312 931 124 -168 931 -88 -292 1009 85 -269 839 360 -116 895 144 NaN NaN NaN -943 -371 -71 122 8 3 546 -300 -435 367 -692 178 596 -995 -151 171 -28 -5 314 348 128 -705 -63 -627 -81 773 -992 233 181 233 -446 970 436 363 -494 477 563 161 -655 -1011 -276 58 -922 192 124 422 -558 287 611 -367 599 366 -884 -626 282 -1772 -557 682 601 -273 483 753 -317 556 154 55 -27 3 -198 -407 305 -100 709 -419 177 2065 -1916 2065 -177 -1916 148 87 -22 17 -8 6 71 -259 353 -820 0 37 5 37 0 5 168 175 -959 201 418 416 547 -841 -91 415 414 138 -974 260 421 427 -11 -81 -983 415 418 -902 151 452 417 412 -180 -89 -985 414 414 -32 -68 -1007 415 416 -639 -294 715 420 418 203 -849 648 424 411 -46 60 -994 413 414 384 150 923 413 414 29 -968 249 416 414 0 0 0 0 0 0 0 0 0 0 0 0 0 992 -85 11 -127 9 -980 -990 38 -70 -1000 -107 -4 4 68 -977 39 -86 -1006 40 47 -1076 -977 13 -1010 5896 2489 1450 3525 1798 1350 6229 2824 921 5846 2763 1809 0 0 0 0 0 0 0
400 -15 1045 6 -288 956 181 -271 932 93 136 951 261 259 950 36 201 941 -319 312 908 124 -239 926 30 -304 1010 41 -263 854 382 -116 910 187 NaN NaN NaN -924 -362 -91 270 72 23 546 -306 -434 370 -692 181 593 -985 -131 183 -26 46 291 348 124 -707 -65 -628 -78 772 -949 249 184 217 -402 889 441 345 -494 483 565 148 -653 -995 -221 101 -539 170 18 419 -565 279 608 -375 595 369 -860 -632 275 -1133 -480 513 601 -307 461 752 -328 546 171 53 -29 2 -326 -304 101 45 814 -585 -684 -492 857 -492 684 857 144 87 -22 17 -39 2 62 -234 369 -831 31 21 -19 21 -31 -19 168 185 -958 202 418 416 576 -814 -84 415 412 135 -963 250 421 427 -9 -66 -1002 415 417 -903 164 466 416 411 -191 -95 -978 413 414 -40 -51 -1020 415 417 -642 -290 724 421 418 216 -842 675 424 411 -41 40 -987 413 412 370 170 901 412 415 31 -969 241 417 415 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -85 19 -127 9 -971 -979 10 -70 -1000 -107 -20 9 57 -1010 39 -86 -1010 40 49 -1070 -977 19 -1010 5881 2488 1450 3537 1801 1340 6227 2823 921 5844 2761 1808 0 0 0 0 0 0 0
433 8 1037 32 -332 956 211 -233 968 94 115 1010 229 241 978 18 221 970 -310 316 910 152 -264 913 78 -333 1025 17 -252 874 399 -60 934 197 NaN NaN NaN -931 -353 -55 428 103 61 550 -311 -427 375 -690 187 590 -992 -129 188 49 33 309 350 121 -705 -66 -629 -75 771 -949 241 204 -22 -282 772 448 335 -494 489 564 140 -650 -992 -205 71 -334 73 6 419 -570 272 607 -380 592 370 -839 -597 253 -665 -388 262 609 -321 445 752 -336 537 179 54 -30 1 -363 -212 -13 160 847 -680 -1216 -967 2947 -967 1216 2947 143 87 -22 17 94 1 78 -353 323 -793 15 -47 -17 -47 -15 -17 168 174 -949 210 418 416 561 -824 -46 415 413 143 -962 249 422 431 -4 -69 -1014 416 418 -903 146 456 416 414 -184 -95 -973 412 413 -37 -38 -1026 415 417 -639 -283 747 418 418 203 -841 684 424 411 -29 30 -991 416 410 379 165 907 413 415 47 -987 256 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 995 -85 19 -127 9 -980 -986 14 -70 -1000 -107 -25 -4 71 -1001 39 -96 -989 30 39 -1070 -1000 29 -1010 5869 2486 1451 3553 1804 1328 6226 2823 921 5844 2760 1807 0 0 0 0 0 0 0
467 3 1027 53 -363 956 202 -240 987 129 82 1028 209 261 991 41 260 968 -321 295 933 131 -255 925 29 -390 1044 4 -271 914 388 -66 950 281 NaN NaN NaN -945 -331 -57 560 138 83 551 -323 -416 380 -688 194 587 -1001 -152 186 109 -5 200 352 112 -708 -65 -630 -71 771 -963 221 218 -132 -280 529 450 326 -493 493 562 135 -649 -988 -198 49 -44 -31 19 420 -567 271 608 -382 590 369 -827 -585 181 -320 -180 31 614 -327 429 753 -343 532 181 62 -32 0 -48 -29 171 18 480 -682 -967 -516 2730 -516 967 2730 150 87 -22 17 91 -7 98 -347 322 -773 27 -48 11 -48 -27 11 168 175 -960 200 418 415 563 -814 -72 414 411 137 -953 244 423 429 -4 -90 -1005 416 418 -903 154 466 419 411 -201 -96 -968 411 414 -38 -47 -1015 415 417 -648 -295 741 418 418 217 -840 681 427 412 -28 26 -989 414 411 382 175 923 414 415 26 -971 210 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -125 9 -980 -955 2 -70 -1000 -117 -20 19 81 -1010 49 -86 -1000 40 58 -1080 -977 48 -1030 5864 2485 1451 3572 1809 1314 6226 2823 921 5844 2759 1806 0 0 0 0 0 0 0
500 -11 1050 79 -298 986 180 -258 985 164 38 1051 209 434 923 -18 270 929 -305 298 915 187 -290 986 15 -394 1070 -40 -291 892 461 -11 988 338 NaN NaN NaN -954 -319 -51 681 179 147 551 -334 -407 386 -685 204 583 -1006 -150 207 265 5 106 352 105 -707 -60 -630 -67 771 -958 197 251 -84 -263 375 451 322 -491 495 560 133 -650 -977 -162 -17 156 -1 -22 421 -566 272 610 -382 590 366 -855 -551 145 58 -28 -173 617 -324 423 755 -347 528 176 69 -34 -1 364 169 224 -257 200 -806 -811 -1660 2537 -1660 811 2537 155 87 -22 17 -32 -23 58 -247 389 -822 18 5 -29 5 -18 -29 168 175 -957 203 417 415 537 -826 -90 414 412 141 -955 284 420 428 -10 -88 -1000 415 418 -893 144 455 417 410 -201 -87 -961 415 413 -28 -38 -1005 414 419 -650 -286 749 419 417 219 -860 682 425 410 -20 63 -1008 414 412 364 161 936 413 415 31 -956 220 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -85 9 -127 9 -989 -997 2 -53 -1000 -117 -20 26 93 -985 49 -86 -1000 41 57 -1060 -955 38 -1020 5866 2484 1450 3594 1815 1298 6226 2823 922 5845 2759 1805 0 0 0 0 0 0 0
533 -1 1031 79 -240 994 141 -265 966 157 49 1092 181 448 951 -72 264 937 -293 318 933 191 -306 970 98 -424 1070 -75 -316 843 539 44 966 368 NaN NaN NaN -956 -321 -53 896 251 192 553 -348 -395 394 -682 216 577 -1012 -151 223 402 -60 15 350 101 -708 -54 -630 -62 773 -958 195 280 63 -198 211 453 323 -490 494 559 130 -653 -964 -141 -36 344 7 34 424 -564 276 612 -381 592 362 -867 -505 129 42 82 -303 626 -315 425 755 -352 527 170 73 -35 -1 437 342 -96 -296 249 -1168 -86 -1453 2019 -1453 86 2019 158 87 -22 17 36 -10 41 -313 365 -832 -47 -34 -24 -34 47 -24 168 173 -961 213 417 415 555 -808 -75 414 413 125 -951 275 420 428 -14 -93 -996 415 418 -893 147 457 417 411 -197 -97 -965 415 413 -48 -26 -1018 415 417 -642 -303 732 419 417 210 -849 660 425 411 -19 56 -1009 415 412 353 172 931 412 415 28 -958 237 417 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 9 -127 5 -980 -970 13 -91 -1009 -107 -10 14 62 -995 44 -81 -1000 40 44 -1065 -1012 32 -1015 5877 2482 1448 3619 1823 1281 6228 2824 923 5846 2760 1805 0 0 0 0 0 0 0
567 -57 1059 21 -315 982 168 -304 987 231 33 1093 159 517 963 -18 235 963 -297 348 907 159 -325 999 94 -442 1030 -77 -375 832 592 -7 971 453 NaN NaN NaN -1009 -339 -31 1046 306 243 556 -365 -377 403 -677 231 571 -1037 -157 251 642 -63 -58 351 88 -711 -43 -629 -54 774 -977 207 309 210 -290 39 450 321 -495 490 558 127 -657 -994 -154 -43 478 11 53 424 -560 283 615 -377 594 356 -857 -472 96 80 228 -366 631 -304 427 754 -357 527 161 77 -34 0 316 402 -34 -221 201 -1117 566 -1875 1651 -1875 -566 1651 162 87 -22 17 21 -4 74 -290 350 -808 18 -35 27 -35 -18 27 168 162 -952 206 417 415 541 -825 -90 414 413 137 -958 271 420 427 -20 -87 -989 414 420 -907 153 441 417 409 -176 -115 -962 414 413 -46 -24 -1029 415 416 -622 -315 730 419 417 219 -850 672 427 412 -26 47 -999 414 412 360 171 913 413 413 47 -956 230 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 992 -85 25 -125 9 -972 -972 32 -90 -1009 -115 -18 17 76 -1020 39 -86 -1000 40 39 -1080 -1000 9 -1010 5896 2480 1445 3645 1830 1264 6230 2825 923 5849 2761 1805 0 0 0 0 0 0 0
600 13 1042 -81 -311 989 182 -370 984 301 32 1041 159 666 955 44 230 955 -309 369 921 131 -300 967 91 -431 1019 -134 -420 829 619 -62 967 517 NaN NaN NaN -969 -279 40 1159 137 254 557 -382 -359 414 -668 247 567 -1067 -167 265 871 -120 -86 349 71 -714 -30 -627 -44 777 -1039 246 362 326 -349 -151 443 317 -503 484 556 124 -664 -1019 -156 -33 771 -10 95 423 -555 297 619 -370 599 348 -897 -520 69 233 459 -409 632 -292 432 752 -361 530 150 81 -33 3 489 106 -96 -518 434 -995 956 -2490 1214 -2490 -956 1214 166 87 -22 17 -18 -24 67 -259 382 -812 39 32 -31 32 -39 -31 168 171 -952 204 417 414 553 -811 -76 415 410 135 -957 267 421 426 -9 -101 -986 416 420 -907 146 448 417 411 -180 -97 -973 414 414 -36 -58 -982 416 416 -632 -304 718 419 418 219 -850 670 424 412 -3 47 -988 413 412 372 154 937 413 416 34 -959 245 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -127 9 -970 -980 27 -90 -1000 -107 -10 1 74 -973 65 -95 -1010 49 39 -1080 -980 37 -1028 5919 2477 1441 3670 1838 1247 6231 2826 924 5850 2761 1805 0 0 0 0 0 0 0
633 -20 1056 -23 -253 951 199 -294 983 319 66 1041 185 726 920 92 235 939 -288 308 913 141 -287 929 100 -420 1051 -20 -451 795 617 -132 954 484 NaN NaN NaN -948 -278 -101 1292 212 323 555 -407 -338 424 -661 264 560 -1013 -148 277 1162 -167 -283 341 49 -718 -12 -625 -31 780 -969 243 369 659 -470 -232 431 311 -519 474 555 117 -673 -974 -89 -24 1125 10 115 422 -542 320 625 -358 606 337 -860 -500 20 814 580 -445 632 -267 450 752 -359 536 132 82 -31 5 -31 219 -402 -50 558 -1302 1058 -331 -310 -331 -1058 -310 168 87 -22 17 12 -5 48 -290 363 -831 0 -49 -21 -49 0 -21 168 174 -958 212 417 415 585 -834 -82 415 414 151 -961 257 421 427 -10 -106 -995 416 421 -895 172 487 418 413 -184 -94 -974 413 415 -44 -45 -1014 415 417 -639 -294 702 420 418 219 -846 670 424 413 -44 46 -993 413 411 374 170 926 413 415 17 -949 250 417 417 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 29 -127 9 -984 -980 23 -68 -1000 -117 -20 19 70 -1014 49 -86 -989 40 49 -1070 -977 38 -1040 5942 2473 1435 3691 1843 1229 6229 2825 924 5847 2760 1804 0 0 0 0 0 0 0
667 -58 1029 23 -265 935 193 -262 960 271 114 1037 200 657 844 247 232 960 -269 297 944 142 -297 912 127 -346 1048 -12 -369 779 621 -116 973 533 NaN NaN NaN -911 -308 -124 1269 432 311 556 -427 -312 433 -656 282 550 -980 -86 205 1397 -95 -175 338 21 -719 6 -624 -15 781 -885 277 349 977 -492 -289 417 305 -539 461 557 106 -683 -950 -53 -53 1437 68 129 423 -529 346 632 -343 616 322 -853 -460 9 1177 740 -576 629 -237 472 753 -353 545 108 83 -30 5 -771 422 -202 706 322 -1276 581 107 918 107 -581 918 169 87 -22 17 -8 7 91 -258 342 -801 -42 -87 31 -87 42 31 168 181 -963 210 418 415 575 -798 -91 415 413 146 -970 267 421 427 8 -105 -1017 416 419 -896 144 455 415 410 -164 -95 -984 413 414 -37 -61 -1024 416 418 -632 -285 722 421 418 219 -845 672 425 411 -43 34 -994 414 409 374 169 917 413 414 19 -958 242 420 416 0 0 0 0 0 0 0 0 0 0 0 0 0 992 -117 15 -127 9 -988 -978 6 -75 -1007 -109 -18 19 70 -1033 49 -86 -1010 40 49 -1080 -1000 29 -1000 5965 2469 1430 3711 1848 1213 6227 2825 924 5846 2759 1803 0 0 0 0 0 0 0
700 -44 1055 13 -275 1013 164 -212 960 300 143 992 243 667 956 160 196 956 -284 337 858 114 -338 987 80 -373 1041 24 -413 765 538 -177 892 506 NaN NaN NaN -980 -283 -25 1357 550 324 558 -446 -288 442 -649 302 540 -984 -84 124 1548 -131 -29 336 -9 -720 24 -623 5 782 -858 326 339 1131 -587 -466 410 292 -558 446 558 93 -694 -998 -62 -89 1331 241 84 420 -517 372 636 -330 627 307 -893 -414 -11 1179 891 -499 623 -209 492 751 -345 556 85 87 -30 5 484 -102 19 -557 547 -768 -46 -1019 1858 -1019 46 1858 171 87 -22 17 -9 -16 107 -258 358 -777 -74 -24 -2 -24 74 -2 168 180 -968 206 418 414 568 -797 -99 415 411 129 -956 260 421 429 0 -95 -1018 415 419 -888 138 459 417 413 -194 -85 -963 413 415 -23 -36 -1001 414 418 -640 -288 733 419 418 219 -859 666 425 410 -36 26 -982 414 410 373 163 913 413 415 26 -970 244 416 413 0 0 0 0 0 0 0 0 0 0 0 0 0 999 -127 18 -127 8 -971 -988 9 -78 -1000 -99 -10 12 59 -1001 40 -95 -1011 31 48 -1081 -982 37 -1028 5982 2466 1427 3730 1853 1198 6226 2824 925 5845 2758 1802 0 0 0 0 0 0 0
733 -33 1030 -21 -305 983 169 -279 932 241 182 986 266 495 935 298 197 992 -272 317 921 103 -370 984 68 -391 1078 13 -415 793 510 -194 917 430 NaN NaN NaN -985 -237 -6 1524 483 339 560 -462 -254 454 -640 324 529 -955 -18 103 1636 -70 -16 333 -42 -723 42 -623 27 781 -837 362 308 1144 -673 -639 392 281 -584 428 558 81 -707 -1013 -86 -104 1410 311 85 417 -497 400 639 -317 638 291 -915 -399 -43 1207 1045 -343 612 -178 519 747 -336 570 63 89 -30 5 1489 -360 -89 -1568 813 -645 -232 -237 -442 -237 232 -442 173 87 -22 17 3 -9 63 -282 366 -816 -7 -32 -27 -32 7 -27 168 173 -969 198 418 415 561 -805 -71 415 412 155 -951 250 421 428 -8 -86 -1004 419 419 -905 166 451 416 412 -191 -96 -966 412 416 -43 -49 -986 415 417 -644 -295 737 418 419 216 -846 660 425 411 -25 35 -1001 414 411 383 183 899 413 415 28 -965 251 416 416 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -127 13 -127 4 -980 -970 9 -60 -1000 -107 -10 30 76 -997 49 -86 -1007 30 58 -1090 -1003 37 -1019 5990 2464 1425 3749 1858 1185 6226 2824 925 5846 2758 1802 0 0 0 0 0 0 0
767 -54 1038 5 -296 953 193 -285 930 204 133 1005 236 460 919 276 213 961 -279 279 946 152 -370 1021 53 -384 1046 -4 -401 827 485 -186 900 446 NaN NaN NaN -973 -278 -36 1659 435 267 560 -485 -218 467 -629 345 516 -920 -36 149 1655 -132 -47 330 -77 -720 60 -621 48 780 -831 412 288 1125 -730 -674 371 270 -611 410 557 68 -719 -1010 -117 -123 1705 403 156 413 -475 431 642 -301 652 271 -951 -382 25 1619 1130 -175 593 -143 548 744 -317 586 38 88 -30 6 204 -13 -95 -300 565 -915 -24 -457 -512 -457 24 -512 172 87 -22 17 -1 0 54 -280 363 -828 1 -21 -12 -21 -1 -12 168 183 -947 197 418 416 559 -806 -47 414 412 154 -957 278 420 428 -11 -86 -975 414 418 -903 149 470 417 410 -185 -95 -972 413 414 -52 -27 -1020 416 416 -648 -296 729 417 417 203 -852 657 424 410 -30 48 -982 414 412 374 175 930 415 416 37 -975 241 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -122 19 -127 9 -980 -970 9 -80 -1000 -107 -10 11 64 -1005 41 -78 -989 40 44 -1085 -1000 24 -1015 5983 2463 1426 3768 1863 1172 6228 2823 926 5847 2758 1801 0 0 0 0 0 0 0
800 -46 1040 72 -301 992 181 -286 931 225 107 987 259 385 902 348 218 1039 -302 312 947 144 -333 1051 15 -405 1038 11 -366 811 454 -219 912 465 NaN NaN NaN -952 -216 -44 1569 457 195 564 -500 -180 479 -620 364 504 -923 -22 197 1775 -96 -90 326 -116 -714 80 -618 70 779 -837 448 281 1147 -782 -545 345 257 -640 394 556 53 -730 -1015 -57 -146 2165 383 207 403 -445 474 646 -278 667 246 -981 -355 124 1983 1045 -65 571 -100 578 745 -291 600 11 88 -30 6 -230 88 -77 129 474 -987 11 -890 -151 -890 -11 -151 172 87 -22 17 -1 -3 56 -283 364 -824 -50 -22 33 -22 50 33 168 186 -934 178 418 416 543 -820 -79 414 415 127 -936 265 420 428 -16 -88 -990 415 419 -894 153 464 417 410 -194 -108 -962 415 413 -23 -44 -1015 416 415 -654 -292 724 420 417 204 -843 673 424 412 -26 50 -1003 415 412 365 166 930 412 416 37 -970 223 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -125 19 -117 9 -979 -979 18 -96 -1000 -106 -11 19 66 -990 39 -86 -989 30 39 -1060 -1022 38 -1000 5960 2465 1431 3787 1868 1161 6230 2823 927 5850 2758 1801 0 0 0 0 0 0 0
833 -43 1057 8 -286 1040 194 -321 972 229 91 1000 303 425 942 380 146 990 -297 291 919 116 -388 985 89 -415 1070 0 -442 838 456 -310 905 356 NaN NaN NaN -911 -116 -24 1639 415 114 567 -513 -143 492 -608 384 491 -880 29 230 1726 -107 -99 324 -154 -708 99 -614 92 778 -791 462 298 1175 -795 -339 325 242 -666 380 554 36 -740 -983 79 -125 2228 320 141 397 -408 513 651 -252 681 221 -968 -383 191 2081 897 -91 554 -56 602 747 -264 610 -16 87 -30 7 93 6 -70 -197 538 -911 48 -160 -345 -160 -48 -345 172 87 -22 17 10 -1 83 -282 351 -798 -70 -72 -94 -72 70 -94 168 176 -966 228 418 415 593 -820 -80 414 411 132 -957 265 421 427 -11 -96 -996 417 417 -901 138 449 416 409 -192 -112 -967 412 413 -18 -63 -992 416 416 -628 -305 724 421 417 211 -845 676 423 412 -32 58 -997 417 412 365 174 938 412 415 39 -949 217 418 416 0 0 0 0 0 0 0 0 0 0 0 0 0 994 -115 19 -123 9 -980 -980 19 -60 -1009 -117 -24 25 67 -984 45 -92 -1006 44 43 -1074 -987 37 -1040 5919 2468 1439 3806 1874 1150 6232 2821 929 5853 2759 1800 0 0 0 0 0 0 0
867 -68 1035 -11 -219 967 161 -357 969 177 109 1002 319 406 903 329 154 923 -289 376 942 162 -345 962 13 -444 1083 -10 -463 828 409 -350 875 325 NaN NaN NaN -909 -59 -13 1586 268 39 569 -519 -110 505 -595 401 479 -820 153 268 1497 -165 -155 321 -185 -702 114 -608 110 778 -658 478 303 1076 -631 -75 313 227 -685 372 554 17 -745 -946 142 -128 2015 110 89 392 -373 544 656 -226 692 198 -916 -340 151 1990 730 -248 543 -12 614 748 -240 617 -44 87 -30 7 -88 33 -15 -23 480 -897 116 78 102 78 -116 102 172 87 -22 17 -12 10 50 -275 359 -836 -27 15 92 15 27 92 168 175 -959 213 418 415 555 -810 -90 415 411 143 -964 280 421 427 -15 -94 -996 415 419 -888 163 444 417 410 -192 -101 -973 413 414 -31 -27 -1014 416 417 -622 -312 729 418 417 211 -852 676 424 410 -17 50 -993 413 412 364 180 919 414 414 19 -957 239 418 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -111 9 -127 9 -977 -970 19 -50 -1000 -117 -17 22 90 -1015 49 -96 -997 47 51 -1073 -983 22 -1030 5855 2470 1449 3821 1877 1138 6230 2817 929 5852 2757 1799 0 0 0 0 0 0 0
900 14 1097 36 -271 974 173 -289 911 159 112 1079 347 265 861 413 183 914 -273 270 922 180 -324 1011 -53 -465 1089 -10 -432 823 382 -335 862 288 NaN NaN NaN -962 -44 6 1389 209 4 572 -527 -78 516 -583 416 469 -799 266 224 1206 -195 -124 315 -214 -696 123 -603 126 778 -590 477 443 743 -440 105 302 211 -701 371 551 3 -748 -964 142 -134 1769 -90 -4 394 -339 570 663 -203 698 178 -931 -301 51 1731 716 -397 530 36 624 747 -219 624 -71 87 -30 7 -20 23 -22 -83 491 -894 106 62 -31 62 -106 -31 172 87 -22 18 -36 -26 35 -264 410 -834 -118 -239 -132 -239 118 -132 168 175 -959 201 417 416 563 -820 -80 415 411 144 -972 279 421 428 2 -93 -998 415 418 -890 148 463 416 410 -175 -90 -977 414 414 -22 -37 -1029 416 418 -648 -297 737 419 418 231 -847 671 425 411 -40 46 -1003 415 410 368 187 931 413 413 57 -968 230 418 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 9 -989 -971 9 -90 -1000 -116 -11 44 75 -979 39 -76 -1000 40 39 -1070 -1000 19 -1000 5776 2474 1461 3836 1881 1125 6228 2813 930 5851 2755 1797 0 0 0 0 0 0 0
933 -90 1037 66 -226 1027 162 -298 924 159 58 1039 360 243 892 367 165 984 -269 250 908 219 -292 1062 -142 -476 1022 9 -421 838 353 -289 907 304 NaN NaN NaN -1042 -59 31 1134 164 -88 575 -529 -51 527 -573 427 460 -895 292 187 914 -217 -112 309 -233 -693 128 -599 137 779 -646 515 484 259 -369 120 296 206 -708 374 546 -4 -750 -1019 179 -186 1581 -165 -105 399 -302 585 669 -183 703 159 -1041 -328 31 1585 586 -432 518 83 629 745 -201 629 -95 87 -30 7 -29 24 -15 -74 486 -890 105 87 60 87 -105 60 172 85 -22 19 -137 0 -30 -198 444 -920 18 -535 -368 -535 -18 -368 168 173 -949 201 417 415 558 -807 -106 415 412 144 -963 280 421 428 -15 -79 -1009 417 418 -893 152 457 416 410 -183 -84 -979 413 414 -8 -45 -1006 415 417 -629 -289 728 419 418 210 -859 637 424 411 -30 36 -990 415 409 372 149 933 413 413 24 -956 248 415 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -121 9 -974 -990 0 -50 -994 -117 -22 9 66 -980 39 -96 -1000 30 49 -1070 -977 29 -1010 5687 2479 1476 3854 1885 1111 6226 2808 931 5850 2754 1796 0 0 0 0 0 0 0
967 -38 1049 102 -230 1028 111 -350 964 148 118 959 350 266 964 313 115 997 -290 305 922 206 -272 992 -36 -401 1060 52 -423 847 331 -340 892 285 NaN NaN NaN -1082 -32 -1 851 145 -172 582 -524 -30 534 -566 435 453 -976 317 245 509 -265 -56 299 -244 -688 129 -594 144 781 -699 503 513 -293 -238 45 294 212 -711 381 539 -3 -751 -1053 223 -256 1336 -72 -250 405 -268 597 672 -167 708 141 -1109 -328 151 1676 196 -449 510 133 620 748 -181 627 -120 87 -30 7 -17 62 -18 -81 450 -912 -6 107 94 107 6 94 172 81 -21 21 -124 115 30 -200 306 -915 173 -779 -299 -779 -173 -299 167 168 -961 213 417 416 569 -815 -86 415 412 145 -954 240 423 429 2 -84 -999 415 418 -911 154 465 416 413 -194 -84 -944 413 414 -42 -41 -1014 414 418 -639 -278 716 419 418 219 -851 666 425 411 -7 22 -997 413 411 365 160 973 411 414 3 -949 240 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1007 -127 26 -127 0 -980 -980 -9 -110 -995 -110 -27 16 64 -999 49 -86 -1017 37 34 -1070 -1000 26 -1030 5594 2486 1492 3873 1890 1097 6226 2804 932 5851 2754 1795 0 0 0 0 0 0 0
1000 -77 1038 62 -294 948 161 -402 927 123 192 904 350 308 1025 281 93 971 -288 221 941 160 -242 969 -8 -305 1039 39 -445 857 340 -318 933 125 NaN NaN NaN -1111 -59 -47 551 155 -210 584 -520 -15 538 -563 440 446 -1030 318 295 307 -229 -51 293 -252 -691 129 -590 148 783 -753 468 565 -554 -93 16 294 229 -705 389 532 4 -752 -1096 266 -210 1180 36 -353 405 -238 608 674 -153 712 124 -1143 -238 171 1049 -214 -391 509 171 611 753 -168 622 -136 87 -30 6 -13 76 -22 -77 441 -922 -32 161 60 161 32 60 172 80 -21 21 -8 104 260 -233 199 -680 -72 -1204 -904 -1204 72 -904 167 185 -954 208 417 414 565 -812 -67 414 412 154 -953 262 420 428 -18 -100 -993 416 419 -913 154 456 418 414 -197 -91 -959 412 414 -48 -27 -1006 416 416 -641 -282 729 419 418 209 -850 672 424 412 -27 80 -1008 415 411 379 169 947 413 415 21 -958 248 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 19 -127 0 -970 -999 9 -52 -990 -117 -20 10 65 -1019 39 -86 -1000 30 39 -1060 -977 38 -1010 5503 2492 1508 3893 1896 1083 6226 2799 934 5851 2754 1794 0 0 0 0 0 0 0
1033 -83 1010 123 -338 965 130 -431 924 120 266 903 350 331 951 383 27 953 -257 211 933 181 -216 944 -12 -258 1037 89 -354 918 381 -184 1000 299 NaN NaN NaN -1059 -81 -28 109 125 -236 591 -521 -7 539 -564 442 443 -1033 279 324 136 -148 -92 287 -257 -691 128 -588 149 785 -786 493 610 -444 -17 51 295 248 -703 397 527 9 -752 -1139 281 -55 406 -66 -531 410 -216 617 677 -149 712 113 -1127 -215 164 102 -311 -372 516 191 602 756 -166 618 -141 87 -30 6 -79 52 -11 -7 461 -905 -16 112 41 112 16 41 172 81 -21 22 -358 137 -269 -90 447 -1247 751 106 1289 106 -751 1289 167 175 -953 218 418 414 605 -806 -89 414 413 154 -955 256 421 429 -18 -84 -973 417 416 -903 144 467 417 411 -204 -96 -954 416 413 -38 -66 -1005 417 416 -648 -289 730 418 418 228 -841 671 426 413 -28 58 -1001 414 411 385 172 910 413 415 33 -974 255 416 414 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 19 -127 9 -974 -984 24 -98 -1005 -111 -24 19 57 -992 39 -96 -1010 40 39 -1080 -1000 19 -1010 5419 2499 1523 3916 1902 1068 6228 2796 936 5853 2754 1794 0 0 0 0 0 0 0
1067 -111 1011 208 -372 885 89 -353 994 51 243 886 350 259 881 322 -54 913 -236 154 961 151 -167 915 6 -317 991 69 -315 961 412 -86 1021 349 NaN NaN NaN -1015 -117 -107 -37 162 -222 595 -517 -3 537 -567 442 441 -999 230 309 -18 -110 -53 283 -257 -692 125 -586 149 787 -756 519 643 -405 -37 144 295 258 -698 406 521 13 -751 -1057 190 3 -239 -204 -567 419 -204 616 680 -153 708 111 -1042 -281 195 -444 -396 -552 521 198 593 757 -174 613 -143 87 -30 6 2 29 -23 -87 482 -898 -23 -54 -59 -54 23 -59 172 84 -21 20 -435 -6 -89 30 521 -1053 1107 1235 3305 1235 -1107 3305 165 188 -969 229 417 414 573 -831 -84 414 413 125 -949 253 423 426 -6 -101 -993 415 417 -901 135 463 417 411 -195 -100 -964 415 413 -38 -57 -1015 415 417 -651 -267 730 418 418 209 -860 671 425 410 -28 56 -1000 414 411 378 166 912 413 414 36 -964 244 417 417 0 0 0 0 0 0 0 0 0 0 0 0 0 993 -106 15 -127 9 -987 -977 16 -86 -1000 -110 -20 12 80 -1003 58 -86 -1010 30 39 -1050 -1009 32 -1006 5349 2505 1536 3939 1909 1053 6230 2792 938 5855 2755 1795 0 0 0 0 0 0 0
1100 -129 1023 275 -256 811 115 -251 991 -42 236 910 350 230 874 295 -129 908 -237 126 954 151 -133 894 -7 -371 993 85 -237 922 405 -79 952 318 NaN NaN NaN -950 -122 -63 -71 310 -138 599 -512 1 534 -571 444 438 -978 204 306 -96 -147 93 274 -252 -694 121 -584 148 789 -744 559 637 -640 -67 290 299 271 -691 419 514 18 -748 -985 121 27 -481 -271 -464 425 -206 610 684 -160 703 112 -943 -303 203 -823 -400 -719 527 198 591 758 -190 607 -143 87 -30 6 32 35 -32 -120 479 -908 -10 -43 6 -43 10 6 172 90 -21 16 591 269 352 -724 -97 -543 -140 282 1212 282 140 1212 162 180 -966 203 417 415 568 -813 -80 414 411 146 -951 275 420 426 -10 -92 -974 416 419 -893 148 447 417 410 -185 -107 -945 413 413 -39 -56 -1026 416 418 -631 -295 730 418 417 228 -859 686 425 411 -29 47 -999 414 412 368 181 939 412 415 28 -939 205 416 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 9 -980 -980 -9 -80 -1009 -117 -20 37 58 -1010 39 -76 -1000 50 49 -1070 -1022 29 -1010 5294 2510 1546 3961 1915 1038 6231 2789 940 5856 2756 1795 0 0 0 0 0 0 0
1133 -172 1042 342 -205 874 236 -206 961 -24 242 869 371 328 1074 88 -146 905 -284 -9 999 120 -110 929 24 -365 936 155 -243 903 294 -158 966 242 NaN NaN NaN -994 -229 -29 -152 199 -46 601 -512 4 532 -574 444 437 -1021 204 303 -316 -211 314 269 -247 -699 112 -581 147 793 -783 586 641 -1144 -147 445 304 291 -678 438 503 27 -745 -967 28 12 -761 -350 -468 434 -214 597 688 -172 696 116 -890 -403 195 -1011 -407 -831 531 193 585 757 -211 602 -143 87 -30 6 -33 34 -25 -54 483 -905 -69 32 5 32 69 5 172 88 -21 16 368 210 339 -520 7 -586 -678 -3 -999 -3 678 -999 158 173 -964 203 418 415 549 -825 -79 415 411 173 -974 261 421 428 -2 -84 -978 416 418 -904 153 446 417 410 -182 -86 -970 414 414 -48 -47 -1019 416 416 -642 -277 730 419 416 219 -849 697 425 414 -49 57 -991 416 410 357 161 946 411 415 29 -968 224 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 9 -127 9 -980 -970 9 -74 -1009 -127 -20 20 64 -979 39 -105 -1000 44 45 -1079 -977 48 -1020 5255 2512 1553 3980 1920 1022 6228 2784 941 5854 2755 1794 0 0 0 0 0 0 0
1167 -372 1111 492 -118 1013 217 -269 967 -64 242 748 428 389 1209 -32 -163 989 -309 105 1002 -3 -156 938 -9 -356 864 275 -341 993 272 -233 1019 249 NaN NaN NaN -928 -277 -14 -87 275 27 599 -513 8 530 -576 446 434 -1005 91 370 -603 -397 407 257 -238 -706 101 -575 142 799 -889 554 765 -1497 -366 637 304 314 -657 461 486 38 -742 -949 1 -47 -755 -290 -243 442 -224 586 689 -181 691 123 -909 -471 200 -876 -466 -958 531 196 581 757 -232 594 -146 87 -30 6 -22 32 -30 -65 487 -906 -52 -24 -40 -24 52 -40 172 83 -22 18 -1022 -784 1111 938 870 191 -400 466 -1641 466 400 -1641 154 181 -956 195 418 416 563 -807 -87 414 411 132 -956 271 421 429 9 -95 -1007 415 418 -900 156 450 416 410 -184 -94 -964 414 414 -32 -43 -1006 413 418 -638 -305 718 419 417 227 -850 659 424 411 -44 43 -998 413 413 354 190 930 412 414 33 -960 219 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 22 -127 9 -983 -970 20 -77 -996 -117 -23 9 63 -1010 39 -86 -1003 50 49 -1070 -1000 41 -1020 5235 2513 1556 3998 1924 1007 6226 2780 942 5852 2754 1794 0 0 0 0 0 0 0
1200 -307 1104 260 -89 1082 179 -397 1023 -106 252 654 541 288 1189 85 -175 1014 -345 376 1013 -59 -166 935 89 -260 843 320 -330 1082 330 -91 1140 253 NaN NaN NaN -947 -287 -77 137 248 48 601 -515 16 530 -577 449 430 -997 57 429 -543 -557 551 240 -232 -713 91 -566 138 808 -918 592 792 -1804 -765 1026 298 339 -639 491 461 47 -737 -943 -29 10 -682 -304 53 450 -236 577 691 -187 686 132 -840 -536 200 -652 -465 -1081 535 204 578 756 -251 585 -152 87 -30 6 -13 33 -36 -78 490 -910 -18 -147 1 -147 18 1 172 81 -22 18 -278 -186 320 51 474 -579 121 518 -408 518 -121 -408 152 181 -961 190 417 415 562 -827 -83 415 412 161 -949 273 421 428 -8 -87 -988 414 419 -887 149 452 418 412 -164 -85 -984 414 415 -36 -43 -1012 415 419 -633 -283 725 419 418 190 -847 671 423 410 -40 37 -990 414 409 361 172 929 411 414 36 -958 240 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -127 29 -127 9 -970 -970 0 -99 -1000 -107 -20 9 94 -1029 57 -76 -1010 30 49 -1070 -978 38 -1020 5233 2513 1557 4017 1929 993 6225 2777 943 5851 2754 1793 0 0 0 0 0 0 0
1233 1 970 -239 -100 1117 225 -374 1090 -151 320 716 564 233 1148 154 -168 1055 -339 562 857 -87 -172 945 38 -228 947 414 -220 1108 373 -7 1166 237 NaN NaN NaN -925 -219 -42 108 221 95 599 -516 24 529 -576 453 428 -1040 148 510 -653 -850 805 216 -224 -721 75 -552 135 819 -924 477 933 -2418 -1118 1251 294 368 -612 528 426 59 -733 -928 -28 59 -767 -465 250 456 -251 565 694 -190 679 145 -848 -743 322 -510 -521 -1511 534 222 573 756 -275 570 -166 87 -30 6 -108 68 10 22 442 -895 -9 32 -115 32 9 -115 172 81 -22 18 371 354 -402 -737 133 -1295 262 115 325 115 -262 325 151 171 -950 196 418 414 565 -812 -93 414 412 130 -944 282 421 428 -18 -76 -997 415 418 -888 150 452 418 413 -184 -96 -964 413 415 -58 -36 -1009 415 415 -638 -285 735 419 418 220 -856 676 425 411 -33 23 -1002 413 410 382 164 939 415 414 19 -956 230 414 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 9 -980 -994 19 -89 -1009 -117 -30 16 73 -987 49 -86 -1000 40 39 -1070 -1022 48 -1030 5248 2512 1555 4036 1934 979 6224 2774 944 5851 2754 1793 0 0 0 0 0 0 0
1267 -125 1128 0 -153 1121 247 -222 1173 -87 311 773 621 324 1129 117 -111 1029 -334 256 902 -319 -170 936 3 -271 1010 429 -164 1110 371 -1 1191 311 NaN NaN NaN -898 -256 13 221 90 144 597 -520 29 531 -573 457 426 -1038 252 644 -652 -1280 1141 175 -214 -729 55 -530 135 835 -905 267 1083 -2679 -1488 1310 289 403 -579 565 381 71 -728 -971 -35 93 -796 -664 321 465 -270 548 699 -193 670 160 -844 -840 479 -156 -755 -1878 532 254 559 759 -296 549 -187 87 -30 6 -119 54 -9 21 465 -905 10 -466 -89 -466 -10 -89 172 81 -22 18 -154 -149 187 -103 466 -690 -36 -154 -154 -154 36 -154 151 185 -955 209 417 415 549 -825 -82 417 412 175 -968 274 420 428 -8 -96 -995 416 419 -911 149 470 416 412 -203 -91 -973 412 414 -40 -30 -1023 415 416 -633 -278 705 419 418 241 -869 674 427 411 -33 40 -997 414 410 375 156 931 414 414 27 -959 235 416 418 0 0 0 0 0 0 0 0 0 0 0 0 0 997 -127 22 -127 9 -980 -990 6 -71 -1000 -127 -10 32 66 -1017 39 -86 -1000 40 49 -1070 -977 29 -1010 5274 2512 1553 4056 1940 966 6225 2772 945 5853 2754 1792 0 0 0 0 0 0 0
1300 -353 1033 -264 -110 1089 273 -77 1131 -81 219 814 680 364 1047 121 -6 1001 -324 406 855 -449 -137 934 36 -355 1072 451 -155 1029 382 -42 1116 312 NaN NaN NaN -837 -327 -38 148 -34 77 593 -526 34 534 -571 457 424 -914 411 817 -991 -1923 1438 119 -196 -741 27 -496 133 858 -856 176 1044 -3380 -1702 1391 286 445 -533 605 327 90 -720 -1006 -148 218 -850 -1100 175 479 -291 524 710 -199 653 174 -790 -1024 543 749 -905 -2345 522 303 534 764 -312 519 -222 86 -30 7 85 57 -33 -194 455 -913 14 -228 -192 -228 -14 -192 172 81 -22 18 46 -1 32 -333 350 -842 -62 -92 3 -92 62 3 151 170 -960 211 419 415 551 -813 -71 414 412 129 -966 255 421 428 -1 -104 -977 417 418 -906 151 458 417 410 -197 -80 -965 413 414 -38 -61 -990 415 417 -637 -298 734 418 418 213 -853 688 425 412 -33 55 -1004 413 411 374 165 920 413 415 23 -956 240 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -85 9 -127 9 -980 -990 10 -80 -1009 -127 -20 47 66 -1009 49 -96 -989 30 49 -1080 -977 29 -1020 5308 2511 1551 4076 1945 953 6227 2770 947 5856 2754 1791 0 0 0 0 0 0 0
1333 -35 1100 -255 -153 953 287 -107 1061 -37 229 868 698 386 927 128 19 931 -265 794 780 -375 -148 965 -15 -406 1081 401 -152 951 414 -50 1021 345 NaN NaN NaN -878 -350 -128 94 -3 -1 592 -526 34 535 -572 457 422 -1098 363 849 -1103 -2476 1637 46 -172 -747 -2 -455 129 881 -974 71 1115 -4334 -1768 1256 284 496 -472 644 262 121 -708 -1024 -131 228 -353 -1234 98 500 -301 499 724 -198 635 182 -894 -1210 690 1635 -1221 -2912 509 379 493 773 -320 478 -269 86 -30 7 106 54 -62 -222 471 -934 -54 -134 -108 -134 54 -108 172 81 -22 18 32 -27 49 -317 370 -819 -57 -132 -44 -132 57 -44 151 163 -958 211 417 416 574 -826 -88 414 414 127 -961 268 421 427 -10 -95 -1001 416 421 -902 143 456 416 410 -172 -107 -969 414 413 -35 -35 -1013 415 417 -636 -276 748 418 417 222 -845 685 425 413 -34 45 -1006 414 412 374 174 919 413 415 10 -960 230 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 3 -987 -980 26 -70 -1000 -117 -20 3 73 -1000 49 -76 -1010 40 49 -1083 -1000 19 -1000 5347 2511 1548 4098 1951 940 6229 2769 948 5860 2754 1790 0 0 0 0 0 0 0
1367 257 1099 -444 -268 1012 181 -105 998 61 224 843 715 462 958 70 93 987 -294 705 622 -289 -123 975 -68 -387 1050 347 -242 866 456 -78 980 335 NaN NaN NaN -1003 -295 -171 10 85 -3 596 -523 38 534 -574 457 420 -1392 215 751 -857 -2877 1737 -37 -148 -745 -27 -410 129 902 -991 -20 1146 -4453 -1709 626 276 546 -407 672 194 156 -698 -1198 -156 353 -228 -1459 -236 521 -305 473 741 -199 614 185 -894 -1104 780 1384 -1626 -3233 495 460 444 778 -332 431 -315 86 -30 7 -47 6 -110 -76 552 -961 -7 -388 310 -388 7 310 172 81 -22 18 -54 -37 90 -225 380 -797 -6 -48 -56 -48 6 -56 150 184 -959 202 417 415 555 -821 -90 417 411 136 -963 279 421 427 -6 -95 -1004 415 417 -902 138 456 417 410 -187 -103 -941 414 413 -18 -48 -1020 414 417 -654 -287 731 418 418 212 -838 658 424 410 -27 48 -992 413 412 373 155 909 414 415 27 -939 211 418 414 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -120 9 -127 12 -980 -987 9 -87 -1000 -127 -20 12 66 -960 58 -105 -1010 40 58 -1070 -1000 38 -1020 5381 2509 1546 4115 1956 927 6226 2766 947 5860 2751 1787 0 0 0 0 0 0 0
1400 4 903 -283 -353 1095 161 -195 980 100 260 830 684 683 1003 7 167 960 -228 547 846 -87 -146 1004 -44 -375 1041 351 -350 882 460 -109 975 414 NaN NaN NaN -1174 -223 -48 -300 140 37 597 -525 37 530 -576 457 422 -1218 38 582 270 -3062 1358 -121 -134 -728 -36 -365 141 920 -859 -91 1147 -3646 -1752 -271 262 589 -337 683 132 182 -695 -1073 -252 345 -739 -1577 -765 554 -307 439 759 -213 586 188 -673 -1160 783 280 -1982 -3666 461 525 397 773 -362 381 -355 86 -30 6 -15 52 14 -74 436 -881 165 2216 -737 2216 -165 -737 173 81 -22 19 38 23 24 -329 331 -859 71 -15 64 -15 -71 64 150 185 -959 201 419 415 564 -801 -81 415 411 143 -955 264 421 428 -1 -94 -993 413 418 -903 154 450 416 410 -195 -102 -979 414 414 -38 -38 -1007 416 417 -640 -295 735 418 418 203 -843 676 423 412 -32 47 -989 414 411 364 167 926 413 415 38 -976 228 417 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 9 -970 -970 9 -70 -1000 -117 -20 -9 66 -990 49 -86 -989 40 49 -1060 -977 48 -1040 5415 2509 1545 4132 1960 913 6224 2762 947 5859 2748 1784 0 0 0 0 0 0 0
1433 -417 1080 -284 -201 885 160 -217 885 144 310 851 669 873 980 -12 159 966 -221 232 1131 399 -170 1016 -68 -353 1060 382 -348 827 479 -148 918 457 NaN NaN NaN -1185 6 -64 -388 -50 188 596 -530 33 524 -576 458 429 -1041 65 491 1444 -3077 936 -199 -152 -700 -30 -322 169 931 -772 -58 1217 -2844 -1995 -1140 229 623 -284 682 74 196 -701 -1150 -347 233 -1068 -1315 -1340 585 -300 403 771 -239 560 185 -615 -1376 918 -105 -2403 -4060 411 581 360 762 -400 324 -393 83 -30 6 -375 -166 -91 247 734 -874 -173 229 -1429 229 173 -1429 171 81 -22 19 -6 -40 87 -273 371 -788 56 9 -12 9 -56 -12 150 185 -968 211 418 415 566 -828 -76 416 412 135 -944 241 421 428 -15 -79 -985 414 418 -894 153 444 416 410 -184 -93 -992 413 414 -56 -52 -1002 415 418 -616 -305 736 419 416 204 -836 667 425 412 -54 41 -988 414 412 365 177 951 411 415 29 -967 268 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 997 -127 19 -127 9 -973 -977 19 -93 -1000 -114 -23 6 82 -993 46 -89 -1010 47 42 -1073 -1000 25 -1017 5447 2509 1546 4150 1964 899 6221 2759 948 5860 2745 1781 0 0 0 0 0 0 0
1467 -15 1426 258 -187 919 128 -177 937 170 236 977 699 997 964 -79 107 952 -181 174 1058 -304 -164 1044 -25 -458 1046 265 -422 797 628 -69 973 749 NaN NaN NaN -1012 -112 -269 -799 29 360 593 -532 21 510 -582 457 438 -631 75 259 2530 -2867 708 -282 -187 -653 -16 -283 211 935 -546 -104 1154 -2179 -2617 -1911 163 655 -235 670 14 193 -717 -1031 -505 327 -1317 -1655 -2183 612 -278 361 788 -275 523 174 -256 -1242 818 240 -2651 -4413 335 640 309 746 -435 258 -433 80 -29 7 -382 -152 -104 235 733 -888 -118 -1965 -539 -1965 118 -539 169 81 -22 19 33 -11 46 -319 352 -828 27 64 -16 64 -27 -16 150 173 -958 209 418 415 570 -821 -66 415 412 154 -954 240 420 429 -15 -87 -987 414 417 -892 154 464 418 411 -169 -99 -975 413 414 -51 -65 -1008 415 416 -622 -320 721 419 417 218 -857 680 425 411 -30 35 -991 414 412 365 184 931 411 415 38 -958 227 418 419 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -113 19 -120 9 -980 -990 9 -60 -1003 -117 -20 19 73 -983 39 -83 -1007 40 42 -1077 -977 22 -1007 5478 2511 1548 4169 1970 884 6220 2756 948 5862 2742 1778 0 0 0 0 0 0 0
1500 22 891 203 -183 892 13 -87 894 283 195 1009 711 1164 948 -216 105 921 -210 390 821 24 -187 1037 -49 -553 1044 280 -492 793 816 19 992 801 NaN NaN NaN -861 -295 -76 -957 299 388 588 -541 12 497 -590 455 445 -366 319 39 3360 -2348 753 -340 -244 -603 -7 -253 273 928 -312 -145 1076 -1667 -3321 -2625 84 676 -205 649 -52 174 -739 -809 -886 447 -2407 -1643 -3306 655 -240 307 800 -336 473 157 150 -1134 748 66 -2621 -4731 257 680 275 721 -471 187 -473 80 -29 8 174 108 74 -274 331 -846 233 1012 -68 1012 -233 -68 169 81 -22 19 40 -35 65 -322 364 -801 60 54 41 54 -60 41 150 168 -959 202 418 415 572 -813 -102 414 412 134 -962 261 419 428 -8 -109 -984 415 417 -902 154 466 416 412 -159 -108 -976 412 414 -29 -67 -976 415 416 -641 -298 720 419 417 219 -844 682 425 413 -36 38 -980 414 411 363 187 916 412 413 28 -959 231 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -127 19 -127 9 -990 -970 28 -80 -1000 -117 -20 38 76 -990 49 -86 -989 30 49 -1070 -1000 19 -1000 5511 2515 1553 4190 1975 870 6220 2752 950 5864 2740 1775 0 0 0 0 0 0 0
1533 -79 956 206 -119 962 -51 -18 930 549 154 997 816 1519 935 -239 124 867 -204 32 939 156 -97 1036 -30 -552 1099 315 -524 705 923 -8 1002 914 NaN NaN NaN -884 -325 -88 -139 53 260 583 -551 5 495 -590 456 446 -275 570 128 3899 -1894 1031 -383 -307 -547 -3 -223 345 912 -269 17 1071 -1557 -3976 -3018 7 686 -176 621 -121 144 -761 -1001 -1029 406 -1895 -1546 -3686 700 -189 262 808 -390 422 129 147 -1464 670 919 -2402 -4935 183 707 250 693 -492 113 -515 80 -30 8 45 -58 -79 -175 574 -900 -143 362 -1 362 143 -1 169 81 -22 18 11 -15 77 -285 347 -807 94 108 76 108 -94 76 150 186 -959 198 418 417 567 -807 -87 414 411 135 -955 270 420 429 -9 -99 -992 415 420 -913 154 466 416 413 -189 -81 -964 411 414 -46 -40 -1002 415 417 -640 -295 711 419 418 219 -847 672 425 411 -39 36 -997 414 411 373 193 905 413 413 41 -966 229 416 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 9 -131 9 -983 -980 30 -56 -1000 -117 -20 32 70 -993 49 -86 -1010 40 58 -1080 -1000 9 -1010 5545 2519 1558 4211 1981 856 6221 2746 953 5867 2739 1773 0 0 0 0 0 0 0
1567 -246 978 368 -107 1006 12 -175 975 478 -102 976 959 1499 1016 -333 187 927 -210 123 995 193 -89 989 10 -680 1066 466 -656 733 1082 125 1037 1136 NaN NaN NaN -936 -352 -176 430 330 30 589 -550 16 497 -591 461 437 -257 633 304 3998 -1560 1223 -426 -356 -480 -1 -193 413 890 -355 250 971 -1240 -4484 -2789 -88 687 -135 592 -187 104 -777 -1022 -863 272 -1511 -1495 -3865 730 -114 220 814 -436 372 97 0 -1339 386 2618 -2326 -4921 81 732 191 672 -489 31 -555 80 -30 7 -94 -11 -50 -23 546 -902 -122 -360 -26 -360 122 -26 169 81 -22 18 4 -17 80 -273 349 -806 20 77 55 77 -20 55 151 169 -957 194 418 415 528 -825 -89 415 413 122 -961 269 422 427 -9 -89 -997 416 419 -903 154 447 418 412 -199 -65 -973 413 413 -28 -38 -1005 415 418 -641 -288 733 419 419 210 -851 671 425 412 -28 47 -1009 414 412 381 173 921 413 414 19 -943 243 415 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 9 -127 15 -974 -977 15 -54 -990 -117 -10 3 59 -981 39 -86 -979 40 49 -1070 -1022 19 -1020 5583 2524 1563 4232 1987 842 6224 2739 958 5870 2739 1772 0 0 0 0 0 0 0
1600 -80 1041 337 -45 1093 83 -153 1067 600 -256 933 955 1511 908 -470 128 1024 -201 135 915 131 -88 1025 -1 -761 890 580 -594 795 1224 362 1146 1248 NaN NaN NaN -1051 -184 -154 262 446 -144 605 -542 37 497 -593 466 429 -341 612 339 3824 -1082 1155 -459 -399 -409 1 -171 474 864 -450 300 940 -1127 -4845 -2267 -166 679 -83 566 -251 62 -783 -1037 -603 180 -788 -1297 -3511 746 -34 187 818 -466 330 66 -287 -1030 204 2730 -2485 -4711 -24 736 122 649 -481 -44 -588 80 -30 7 27 76 -1 -128 427 -900 58 95 -59 95 -58 -59 169 81 -22 18 7 -15 85 -272 344 -802 -44 96 42 96 44 42 151 188 -962 199 418 415 556 -812 -78 413 413 131 -941 257 421 427 -8 -90 -992 416 417 -903 132 458 417 410 -191 -100 -958 412 412 -39 -38 -1015 417 417 -640 -285 770 418 419 209 -841 682 427 410 -28 47 -1000 414 412 371 162 920 412 416 34 -973 234 415 417 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 19 -127 9 -980 -980 0 -70 -1000 -107 -20 9 58 -991 58 -86 -989 50 49 -1060 -1000 29 -1020 5623 2529 1569 4253 1993 828 6225 2726 964 5871 2738 1770 0 0 0 0 0 0 0
1633 -168 970 338 -16 1165 135 -140 1299 774 -293 988 1025 1603 872 -710 60 1192 -268 117 960 269 -70 1026 29 -785 1209 615 -405 549 1225 437 941 1362 NaN NaN NaN -1038 -81 -177 -89 336 -172 620 -533 53 494 -597 468 426 -313 533 347 3673 -572 619 -478 -437 -339 10 -159 527 835 -531 151 777 -1138 -5210 -2141 -241 665 -14 536 -317 21 -782 -928 -151 142 -422 -1115 -2683 745 48 158 824 -476 304 42 -563 -781 254 2490 -2801 -4248 -131 724 68 623 -476 -114 -610 80 -30 7 2 5 -70 -120 527 -924 -29 -157 -21 -157 29 -21 169 81 -22 18 -17 -1 75 -248 342 -823 -49 78 45 78 49 45 151 196 -964 214 417 416 549 -829 -78 414 410 144 -963 264 421 426 -15 -93 -975 414 419 -902 144 449 417 412 -186 -97 -968 415 413 -47 -38 -1005 417 418 -650 -295 740 418 418 209 -851 681 425 410 -7 45 -990 413 412 371 173 905 413 416 36 -978 248 416 417 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -127 19 -127 9 -990 -987 22 -54 -1009 -107 -20 23 76 -1015 49 -96 -1000 33 49 -1080 -1000 19 -1020 5663 2532 1572 4270 1997 814 6224 2708 971 5869 2737 1768 0 0 0 0 0 0 0
1667 -135 1028 336 23 1108 163 -227 897 794 -278 1073 1106 1818 680 -747 -69 1197 -162 278 965 175 -27 973 17 -886 1403 483 -681 400 1376 399 907 1482 NaN NaN NaN -935 -182 -208 231 345 -176 632 -521 69 494 -599 471 419 -388 460 514 3578 -731 79 -492 -470 -276 31 -147 572 806 -489 -139 529 -885 -5303 -2037 -286 646 59 504 -381 -16 -775 -747 125 285 -861 -840 -1715 746 93 144 825 -483 291 30 -681 -455 209 2160 -3074 -3613 -208 696 33 598 -475 -180 -620 80 -30 7 -6 62 -11 -99 450 -901 39 224 2 224 -39 2 169 81 -22 18 -19 -11 68 -248 356 -825 -62 -6 13 -6 62 13 152 165 -958 231 417 415 564 -819 -58 415 412 147 -966 249 421 429 -13 -67 -993 415 418 -901 182 429 417 413 -190 -94 -975 413 414 -47 -51 -1014 416 417 -641 -286 738 418 418 220 -859 671 425 410 -5 30 -989 413 411 363 168 918 413 414 26 -970 236 416 414 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 19 -127 3 -980 -967 28 -81 -1000 -98 -10 3 70 -981 39 -86 -1000 40 58 -1070 -1022 29 -1010 5708 2534 1575 4288 2001 800 6224 2684 980 5867 2736 1767 0 0 0 0 0 0 0
1700 -2 1049 166 -93 1111 -8 -419 1012 922 -271 927 1061 1495 444 -729 -8 961 -185 418 968 61 -59 935 16 -749 1273 369 -701 154 1288 363 611 1377 NaN NaN NaN -912 -197 -169 451 368 -229 639 -514 88 497 -600 475 409 -319 518 403 3889 -1189 -547 -492 -506 -205 64 -134 617 773 -350 -254 320 -1212 -4995 -2200 -305 626 131 465 -445 -42 -764 -628 109 381 -1515 -1243 -849 751 109 127 823 -494 277 36 -564 -180 235 1914 -2922 -2782 -266 673 8 576 -473 -237 -623 80 -30 7 -23 40 -21 -82 477 -901 -2 34 20 34 2 20 169 81 -22 18 -3 -13 67 -264 355 -822 -44 21 -4 21 44 -4 151 167 -952 213 417 417 554 -831 -49 415 411 150 -953 245 421 430 8 -99 -989 415 418 -889 144 451 417 410 -194 -95 -982 414 414 -46 -52 -1004 416 416 -650 -297 724 419 416 215 -848 667 425 412 -41 47 -991 413 414 402 171 919 413 414 35 -976 244 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 990 -107 19 -117 9 -980 -979 9 -71 -1009 -107 -11 20 66 -971 49 -86 -1010 30 39 -1070 -1000 38 -1031 5754 2536 1577 4306 2006 786 6226 2654 991 5865 2737 1767 0 0 0 0 0 0 0
1733 45 1096 -59 -240 1315 8 -132 368 1257 -235 826 1008 1316 115 -462 68 904 -89 415 926 -22 -52 887 4 -599 1038 266 -409 -356 1118 442 134 1226 NaN NaN NaN -865 -186 -130 187 401 -201 649 -512 107 498 -602 478 401 -397 603 237 4143 -1807 -1020 -483 -544 -106 109 -118 663 731 -56 -366 -27 -2040 -4278 -2761 -330 587 217 410 -516 -51 -750 -624 4 357 -2397 -1800 131 760 103 101 815 -512 263 64 -294 -54 275 2164 -2679 -1851 -308 649 -8 563 -466 -287 -619 80 -30 7 -25 27 -25 -81 490 -897 -30 35 -15 35 30 -15 169 81 -22 18 7 -17 65 -274 357 -820 -19 20 14 20 19 14 152 163 -952 209 417 415 570 -803 -91 415 413 121 -966 245 421 428 1 -107 -986 417 418 -898 151 459 416 411 -173 -85 -994 414 415 -34 -40 -1018 415 416 -637 -305 760 419 417 213 -854 666 425 410 -36 35 -984 414 412 365 177 927 412 416 35 -979 252 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 997 -106 19 -124 3 -980 -990 28 -100 -1000 -107 -20 9 85 -1010 39 -96 -1000 20 49 -1070 -977 19 -1020 5799 2537 1578 4325 2011 772 6230 2617 1005 5864 2738 1767 0 0 0 0 0 0 0
1767 -145 1042 -115 -127 1012 60 -399 402 1405 -249 592 872 1648 -92 -165 131 914 -198 379 965 -64 -58 882 -20 -415 795 261 -755 -444 1248 139 48 1338 NaN NaN NaN -910 -276 -161 40 330 -170 662 -508 121 498 -605 480 395 -437 732 15 4403 -2254 -1462 -463 -575 7 163 -100 707 681 291 -447 -302 -2315 -3106 -3596 -359 532 301 342 -577 -72 -738 -628 -248 127 -3042 -2218 1063 772 73 61 796 -542 246 114 97 141 165 2569 -2491 -717 -333 633 -38 559 -453 -346 -602 80 -30 7 -30 41 -15 -73 475 -895 -15 57 4 57 15 4 169 81 -22 18 10 -17 79 -270 352 -808 -19 21 5 21 19 5 152 173 -960 221 417 415 552 -830 -89 417 412 124 -954 275 420 429 0 -86 -997 415 418 -901 152 467 416 411 -194 -85 -964 412 415 -29 -42 -1010 416 417 -633 -303 734 419 421 226 -855 695 426 411 -23 38 -971 414 411 343 165 931 412 414 25 -955 232 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 15 -127 0 -980 -976 19 -79 -1003 -111 -24 15 93 -1016 43 -80 -1004 40 51 -1066 -991 26 -1005 5841 2537 1579 4346 2016 758 6236 2574 1021 5863 2740 1768 0 0 0 0 0 0 0
1800 -136 1072 -62 -253 858 114 -562 604 1277 -273 226 772 1298 151 67 58 958 -63 329 934 -36 -99 911 20 -173 514 399 -937 -284 1054 -74 68 1159 NaN NaN NaN -1026 -187 -90 150 397 -148 671 -507 137 499 -605 484 389 -555 976 -183 4239 -2377 -1578 -433 -596 135 216 -82 745 626 546 -198 -492 -2301 -1779 -4392 -411 453 373 264 -617 -105 -734 -656 -689 -175 -3886 -2414 1671 795 21 15 759 -586 223 179 523 242 42 2131 -2685 611 -319 626 -51 562 -459 -396 -563 80 -30 7 -20 34 -26 -83 485 -901 -14 -52 14 -52 14 14 169 81 -22 18 -5 -13 74 -257 354 -816 -19 -6 17 -6 19 17 152 175 -962 206 419 414 566 -809 -76 416 412 140 -956 275 419 428 0 -95 -1007 414 419 -909 158 466 415 414 -202 -95 -975 412 414 -23 -31 -1022 415 417 -642 -292 726 419 418 201 -839 673 423 410 -24 39 -998 414 412 355 165 929 413 415 25 -952 238 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -106 19 -127 0 -990 -979 1 -79 -1000 -107 -20 28 66 -1010 49 -86 -990 41 39 -1071 -1021 38 -1020 5877 2536 1579 4367 2022 744 6243 2526 1038 5863 2742 1769 0 0 0 0 0 0 0
1833 -171 1023 -11 -216 1018 123 -552 559 1001 -336 123 821 985 428 -3 -56 1040 84 261 967 -40 -174 999 -50 -77 432 624 -747 -22 764 -72 256 918 NaN NaN NaN -1117 -311 -140 891 -2 -35 677 -503 159 511 -596 490 380 -342 1146 -297 3471 -2026 -1604 -386 -600 243 260 -66 771 578 717 214 -544 -1569 -421 -4404 -464 365 412 192 -627 -145 -741 -489 -1049 -252 -3902 -1733 1692 795 -10 -30 715 -628 202 232 791 214 -349 1845 -2746 1607 -292 636 -67 568 -477 -437 -508 80 -30 7 -11 25 -20 -92 486 -892 -3 6 20 6 3 20 169 81 -22 18 -14 -8 66 -250 356 -827 -63 -17 24 -17 63 24 152 182 -965 201 418 415 538 -829 -91 414 412 124 -943 255 420 427 -9 -94 -994 415 418 -903 141 459 416 412 -201 -94 -959 413 413 -26 -53 -1014 416 417 -639 -298 704 419 417 206 -856 672 425 412 -20 49 -1011 414 411 365 155 940 413 415 22 -959 214 417 415 0 0 0 0 0 0 0 0 0 0 0 0 0 997 -85 22 -127 9 -973 -990 9 -90 -1003 -114 -17 24 63 -992 35 -86 -1007 40 46 -1070 -983 22 -1006 5903 2534 1579 4390 2029 731 6252 2472 1058 5864 2746 1770 0 0 0 0 0 0 0
1867 -258 1042 50 -129 894 47 -287 636 665 -263 219 826 777 738 -199 -47 1066 199 200 949 -18 -186 1046 -35 -232 364 670 -584 280 691 43 483 664 NaN NaN NaN -899 -225 -253 634 147 49 679 -502 172 515 -591 496 373 -266 1089 -399 1862 -1264 -1471 -336 -605 311 288 -62 783 548 936 831 -391 -746 478 -3460 -506 294 421 141 -616 -184 -753 -420 -1244 -251 -2952 -1005 1586 779 -36 -64 678 -657 191 268 995 196 -614 756 -2546 2140 -277 651 -81 568 -507 -461 -456 80 -30 7 -16 23 -16 -86 485 -889 -25 10 16 10 25 16 169 81 -22 18 -14 -12 76 -249 357 -816 -52 -9 13 -9 52 13 152 186 -938 192 418 415 553 -792 -71 414 412 139 -957 261 421 428 -8 -87 -977 414 419 -889 141 461 417 409 -190 -97 -959 416 413 -45 -39 -1008 415 416 -644 -286 715 422 417 225 -859 669 424 412 -14 45 -998 413 412 374 155 920 413 414 27 -957 273 415 417 0 0 0 0 0 0 0 0 0 0 0 0 0 996 -88 21 -127 9 -976 -990 19 -90 -1000 -111 -14 29 62 -988 45 -86 -997 36 49 -1066 -1000 23 -1008 5913 2529 1578 4409 2034 717 6257 2414 1077 5861 2747 1770 0 0 0 0 0 0 0
1900 -258 995 45 -137 897 90 -5 686 400 -183 277 820 489 906 -273 -32 977 151 254 911 8 -151 1059 -38 -228 456 682 -312 445 545 39 690 422 NaN NaN NaN -928 -180 -113 579 254 -72 672 -493 189 520 -586 502 365 -393 908 -476 867 -618 -1167 -303 -607 345 304 -68 789 530 1026 1136 -130 -605 777 -2249 -541 238 413 108 -598 -211 -765 -348 -1214 -298 -1825 -233 999 780 -60 -79 652 -678 186 284 1124 -26 -911 -454 -2145 2007 -233 667 -63 558 -544 -470 -414 80 -30 7 -15 35 -22 -84 477 -901 -37 7 -18 7 37 -18 169 81 -22 18 -3 -22 68 -264 368 -816 13 -76 -8 -76 -13 -8 152 175 -967 200 418 417 545 -838 -80 417 411 145 -963 261 421 431 1 -98 -999 415 419 -894 144 462 417 412 -182 -105 -973 413 417 -48 -43 -1011 415 416 -650 -277 724 420 417 204 -856 672 425 411 -41 48 -989 413 411 374 157 929 413 414 20 -949 244 416 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -127 9 -980 -979 2 -71 -1000 -117 -10 29 75 -1001 49 -95 -1009 40 50 -1079 -977 30 -1011 5912 2523 1578 4428 2039 703 6262 2355 1096 5857 2747 1769 0 0 0 0 0 0 0
1933 -241 1002 61 -156 949 119 251 669 382 -161 331 653 143 745 -189 11 937 108 227 949 54 -137 1068 -34 -240 374 579 -76 378 447 107 562 242 NaN NaN NaN -950 -154 -14 287 251 -120 671 -484 202 524 -582 507 359 -453 874 -571 361 -112 -889 -277 -616 358 309 -78 793 519 1077 1086 -12 -232 751 -1064 -566 203 399 94 -583 -228 -774 -345 -1043 -240 -869 434 531 780 -70 -76 638 -691 188 283 1163 -306 -1029 -663 -1334 1435 -195 679 -38 548 -572 -473 -386 80 -30 7 -20 31 -26 -81 484 -902 -10 36 -1 36 10 -1 169 81 -22 18 17 -19 61 -287 363 -818 -4 -63 -29 -63 4 -29 152 185 -939 191 418 415 565 -790 -90 415 411 144 -963 242 421 428 -2 -106 -977 415 417 -891 153 447 416 411 -186 -101 -977 414 415 -41 -53 -1001 415 415 -649 -298 732 417 419 212 -841 663 425 412 -50 45 -982 412 411 372 176 905 413 414 19 -958 229 417 416 0 0 0 0 0 0 0 0 0 0 0 0 0 997 -85 19 -127 9 -975 -985 14 -63 -1000 -107 -30 0 66 -1017 29 -86 -1010 40 39 -1070 -977 29 -1010 5899 2516 1579 4449 2045 690 6267 2297 1116 5854 2748 1769 0 0 0 0 0 0 0
1967 -90 1037 11 -139 993 92 351 436 382 -334 238 532 -75 395 -186 55 895 171 380 894 20 -92 1010 -33 -361 261 507 62 155 372 163 281 -54 NaN NaN NaN -966 -218 -79 108 187 -86 670 -476 213 526 -581 510 355 -502 844 -673 -78 527 -736 -261 -619 349 302 -96 797 514 1109 891 1 867 477 -228 -572 196 372 102 -574 -251 -773 -449 -946 -317 -128 962 333 780 -76 -51 630 -699 199 273 1104 -412 -976 -512 -663 772 -155 691 -18 539 -589 -475 -369 80 -30 7 -10 17 -18 -89 487 -889 12 -3 -7 -3 -12 -7 169 81 -22 18 5 -18 70 -275 362 -812 -3 5 23 5 3 23 152 185 -960 202 420 415 562 -814 -101 415 412 127 -954 249 421 428 -11 -83 -989 416 418 -894 146 454 419 411 -195 -92 -980 414 414 -37 -59 -1007 415 418 -650 -303 727 418 418 212 -843 664 424 412 -33 50 -985 417 410 363 160 901 413 415 19 -967 248 416 414 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 15 -127 5 -980 -980 9 -80 -1009 -96 -30 4 72 -996 39 -88 -1001 36 49 -1064 -1000 36 -1018 5878 2510 1580 4471 2052 679 6272 2244 1134 5853 2749 1769 0 0 0 0 0 0 0
2000 -34 1056 -73 -156 1028 86 221 303 497 -361 71 344 -636 47 -148 105 969 62 376 927 -12 -16 988 20 -280 269 600 43 -175 154 269 -208 -182 NaN NaN NaN -925 -225 -191 -175 -108 -147 673 -474 210 526 -582 507 356 -513 938 -639 -434 1070 -607 -256 -632 336 287 -117 800 513 1199 755 -13 1568 294 256 -575 212 357 122 -568 -276 -766 -579 -954 -299 496 1326 118 782 -82 -22 629 -702 215 255 1140 -345 -822 -1207 -114 413 -147 692 3 526 -605 -471 -368 80 -30 7 -6 14 -19 -93 488 -889 25 -7 -27 -7 -25 -27 169 81 -22 18 -6 -14 71 -265 362 -813 -9 -20 25 -20 9 25 152 182 -959 200 416 416 568 -817 -98 416 414 135 -963 250 423 428 -7 -70 -1020 416 419 -891 161 457 416 411 -191 -101 -973 414 414 -39 -42 -1002 416 417 -644 -277 738 419 416 221 -830 681 425 411 -34 32 -990 415 411 354 175 914 413 414 21 -961 251 416 414 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -127 9 -970 -979 9 -89 -1000 -106 -11 0 58 -1000 40 -86 -1000 39 48 -1080 -1018 18 -1009 5850 2504 1583 4496 2061 669 6278 2198 1152 5852 2751 1769 0 0 0 0 0 0 0
2033 -150 1046 -79 -170 1000 52 -84 82 650 -320 -179 201 -788 -328 -105 97 1083 -10 333 944 -25 -32 937 49 -216 341 657 72 -583 -87 359 -621 -214 NaN NaN NaN -912 -226 -246 -450 -292 -131 685 -474 202 524 -586 501 362 -543 805 -677 -648 1620 -607 -259 -647 326 265 -145 803 514 1067 612 87 2311 81 603 -584 241 345 153 -563 -305 -753 -531 -932 -193 810 1434 -154 785 -76 11 634 -702 230 231 1088 -376 -736 -1801 517 82 -152 690 36 510 -619 -460 -382 80 -30 7 -22 18 -20 -77 488 -892 21 -3 -8 -3 -21 -8 169 81 -22 18 0 -10 70 -270 358 -815 -35 -66 -1 -66 35 -1 152 155 -966 201 417 416 569 -823 -87 414 412 134 -953 271 420 429 -12 -95 -992 417 419 -903 156 455 416 410 -199 -95 -964 413 414 -23 -44 -1014 416 416 -639 -297 726 419 417 230 -852 680 427 412 -40 33 -997 413 411 393 174 918 412 415 29 -972 239 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -85 19 -120 9 -980 -997 21 -53 -1007 -117 -20 14 66 -1000 39 -83 -1000 40 58 -1080 -1000 38 -1020 5817 2498 1587 4525 2070 661 6284 2160 1168 5853 2752 1770 0 0 0 0 0 0 0
2067 -170 1031 -40 -139 995 52 -579 -103 800 -289 -548 -47 -730 -357 251 148 1177 14 316 943 -40 -55 966 51 -56 481 823 -127 -851 -323 44 -867 -275 NaN NaN NaN -945 -300 -267 -625 -393 -195 692 -477 189 522 -592 492 368 -675 704 -650 -702 2148 -701 -263 -659 296 238 -180 806 512 995 625 104 2813 -195 569 -580 279 323 186 -558 -341 -733 -632 -907 -258 1221 1563 -236 787 -71 49 641 -697 247 204 1025 -422 -703 -1849 1069 -161 -157 686 75 496 -628 -445 -402 80 -30 7 -28 22 -18 -68 481 -895 4 -58 -13 -58 -4 -13 169 81 -22 18 -3 -10 69 -268 359 -815 -25 -43 4 -43 25 4 152 168 -944 200 416 416 553 -815 -80 414 412 117 -956 277 420 428 -5 -92 -987 414 418 -903 154 466 416 413 -197 -80 -949 412 415 -8 -49 -1005 415 418 -642 -307 717 419 418 220 -862 675 425 411 -29 54 -1007 415 413 384 154 928 411 415 30 -948 200 416 418 0 0 0 0 0 0 0 0 0 0 0 0 0 996 -72 13 -123 4 -986 -990 28 -60 -996 -113 -20 33 70 -993 39 -82 -994 46 49 -1064 -1003 29 -1024 5783 2493 1592 4559 2081 655 6290 2131 1181 5854 2754 1770 0 0 0 0 0 0 0
2100 -190 1012 -41 -79 966 41 -795 -326 811 -104 -827 -74 -681 -664 932 74 950 26 273 907 -22 -96 937 24 130 691 1001 -184 -1039 -533 -171 -1020 -168 NaN NaN NaN -1006 -277 -235 -871 -306 -219 701 -479 171 517 -599 482 376 -716 639 -560 -823 2478 -921 -267 -675 264 209 -220 805 509 926 657 114 3545 -310 625 -576 319 296 224 -551 -384 -706 -559 -936 -325 1563 1915 -436 787 -61 97 651 -690 267 169 1052 -387 -732 -2402 1442 -353 -172 677 120 476 -637 -425 -432 80 -30 7 -16 19 -22 -75 479 -901 17 -76 -39 -76 -17 -39 169 81 -22 18 -3 -9 69 -272 360 -815 -28 -34 -5 -34 28 -5 151 162 -954 203 417 414 535 -828 -94 413 412 147 -960 261 424 427 -24 -95 -983 416 417 -912 163 456 418 413 -192 -99 -962 415 412 -46 -28 -1015 415 417 -631 -295 730 419 418 209 -850 658 426 412 -19 65 -1019 414 411 352 176 936 412 416 26 -973 235 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -104 18 -127 0 -970 -980 9 -70 -1008 -116 -29 18 67 -1008 40 -88 -991 40 49 -1060 -1000 37 -1019 5748 2487 1597 4598 2094 652 6292 2111 1192 5854 2754 1770 0 0 0 0 0 0 0
2133 -151 1042 10 -184 975 129 -945 -394 905 105 -863 -149 -184 -765 1344 -69 972 -40 300 961 40 -88 951 -10 336 624 1038 -414 -1140 -622 -644 -1105 -41 NaN NaN NaN -1062 -289 -184 -832 -164 -157 707 -490 149 511 -606 474 382 -851 538 -533 -818 2983 -1264 -268 -690 216 179 -269 803 502 946 774 69 4969 -447 694 -554 375 245 273 -536 -443 -665 -618 -983 -486 1934 2426 -648 774 -35 161 660 -682 290 125 1071 -544 -879 -3563 1749 -634 -192 655 189 444 -650 -397 -472 80 -30 7 -3 10 -23 -91 483 -897 28 -84 -48 -84 -28 -48 169 81 -22 18 -4 -14 73 -271 365 -808 -13 -53 29 -53 13 29 151 169 -957 214 417 414 553 -814 -80 414 413 141 -959 270 420 428 -9 -66 -1001 416 416 -893 135 465 417 410 -188 -111 -963 414 415 -47 -57 -1005 415 420 -631 -295 711 419 418 219 -850 714 427 412 -28 47 -1000 414 411 374 187 931 412 415 34 -963 208 415 416 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 21 -127 9 -980 -978 14 -80 -1002 -117 -22 9 75 -997 49 -84 -994 40 49 -1080 -977 29 -1030 5710 2479 1602 4641 2108 650 6291 2098 1199 5850 2753 1768 0 0 0 0 0 0 0
2167 -122 1075 -11 -223 970 28 -958 -331 811 458 -966 81 347 -899 1392 -31 973 -82 291 936 -15 -118 962 19 592 423 901 -693 -1141 -620 -1048 -1125 23 NaN NaN NaN -989 -329 -132 -824 -154 -186 718 -495 130 507 -613 466 388 -897 677 -471 -1695 3348 -1189 -260 -704 158 139 -320 790 504 1024 939 -62 5992 -226 971 -542 426 176 332 -510 -505 -612 -537 -932 -589 2418 3226 -825 759 -2 234 667 -670 319 68 1044 -723 -911 -4462 1948 -1034 -225 612 270 400 -663 -361 -519 80 -30 7 1 8 -24 -97 483 -897 32 -92 -58 -92 -32 -58 169 81 -22 18 -5 -15 74 -270 366 -807 -2 -48 42 -48 2 42 151 179 -962 204 417 413 566 -804 -76 415 410 148 -967 253 421 429 -4 -95 -995 415 420 -902 148 444 417 411 -173 -123 -970 414 414 -38 -58 -1005 415 416 -622 -295 720 419 418 209 -850 681 424 412 -47 49 -990 412 413 372 165 945 414 413 12 -970 244 416 415 0 0 0 0 0 0 0 0 0 0 0 0 0 1000 -127 23 -123 9 -980 -970 19 -70 -1005 -117 -16 5 70 -1000 43 -82 -1000 40 49 -1070 -997 19 -1014 5676 2472 1607 4692 2125 650 6288 2093 1204 5847 2752 1767 0 0 0 0 0 0 0
`

const TARGET_BONE = 'LUA';
const USE_GLOBAL = true;
const AUTO_RIPPLE = true;
const REPEAT = true;
const FPS = 20;

const LIMBS = [{ boneName: "LUA", startCol: 79 }, { boneName: "LLA", startCol: 92 }];

var quatStorage;

let outgoingRequest = false;

let quat_o_t = new THREE.Quaternion(-0.5,-0.5,-0.5,0.5); //Quaternion from OPP space to THREE space

//Translated from python code in hmv-scratch/sample-test-data/graph-gyro-data.py
//The orientation almost makes sense, but it lacks an initial position because it only uses angular velocity.
function madgwickQuaternion(currQuaternion, gyroX, gyroY, gyroZ, elapsedTime) {
    let qGyro = new THREE.Quaternion(gyroX, gyroY, gyroZ, 0);
    let qDot = new THREE.Quaternion().multiplyQuaternions(currQuaternion, qGyro);
    qDot.set(qDot.x*0.5*elapsedTime, qDot.y*0.5*elapsedTime, qDot.z*0.5*elapsedTime, qDot.w*0.5*elapsedTime);
    let qNew = new THREE.Quaternion().copy(currQuaternion);
    qNew.set(qNew.x + qDot.x, qNew.y + qDot.y, qNew.z + qDot.z, qNew.w + qDot.w);
    qNew.normalize();
    return qNew;
}

function quaternionArrayFromFile(dataLines, startCol) {
    let currQuaternion = new THREE.Quaternion(0,0,0,1);
    let quatArray = [];
    for (let i = 0; i < dataLines.length; i ++) {
        let gyroX = parseFloat(dataLines[i][startCol]) / 1000.0;
        let gyroY = parseFloat(dataLines[i][startCol+1]) / 1000.0;
        let gyroZ = parseFloat(dataLines[i][startCol+2]) / 1000.0;
        if (isNaN(gyroX) || isNaN(gyroY) || isNaN(gyroZ)) { continue; }
        currQuaternion = madgwickQuaternion(currQuaternion, gyroX, gyroY, gyroZ, 33.0/1000.0);
        quatArray.push(currQuaternion);
    }
    return quatArray;
}

function parseGyroFile(dataLines, limbs) {
    let boneQuats = [];
    for (let i = 0; i < limbs.length; i ++) {
        boneQuats.push(quaternionArrayFromFile(dataLines, limbs[i].startCol))
    }
    return boneQuats;
}

export default function GeneratedData(props) {

    if (props.data.current.length === 0) {
        if(!outgoingRequest) {    
            let inputArray = inputData.split("\n");
            let linesArray = [];            
            for (let i = 0; i < inputArray.length - 1; i++) { // Using every three lines to triple the speed
                linesArray[i] = inputArray[i].split(" ");
            }
            quatStorage = parseGyroFile(linesArray, LIMBS);
            let indexList = [];
            for (let i = 0; i < quatStorage[0].length; i ++) {
                indexList[i] = i;
            }
            props.data.current = indexList;
            outgoingRequest = true;
        }
        
        props.repeat.current = REPEAT;
        props.FPS.current = FPS;
        props.lastIndex.current = -1;
    }

    React.useEffect(() => {
        
        if (props.timeSliderValue !== props.lastIndex.current && props.data.current.length > 0) { // We need to update the model, because the timeSlider has moved
            let clockwise_down_quat = new THREE.Quaternion();
            clockwise_down_quat.setFromAxisAngle(new THREE.Vector3(0, 0, -1), Math.PI/2);
            let set_y_up_quat = new THREE.Quaternion();
            set_y_up_quat.setFromAxisAngle(new THREE.Quaternion(1, 0, 0), Math.PI/2);
            let align_arm_quat = new THREE.Quaternion();
            align_arm_quat.multiplyQuaternions(set_y_up_quat, clockwise_down_quat);

            const dataObj = { }

            for (let i = 0; i < LIMBS.length; i ++) {
                //This quaternion is the one that we want to get from gyro values.
                let lineQuat = (quatStorage[i][props.timeSliderValue]);
                let transformed_q = new THREE.Quaternion().multiplyQuaternions(quat_o_t, lineQuat);
                let final_q = new THREE.Quaternion().multiplyQuaternions(transformed_q, align_arm_quat); //FIRST align the arm, THEN apply the movement
                let q = final_q;
                dataObj[LIMBS[i].boneName] = q;
                props.lastIndex.current = props.timeSliderValue;
            }

            props.visualizer.acceptData(dataObj)
        }
    });

    return (
        <div>
            <a href="https://github.com/jpiland16/hmv_test/blob/master/src/components/visualizer-screen/menu/components/main-panel/subpanels/Labs/Sam20210605/GyroIntegration.js" target="_blank" rel="noreferrer">View relevant code</a>
        </div>
    )
}